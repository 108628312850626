import './page-404.scss';

const hasAccessToken = document.cookie
    .split(';')
    .some(cookie => cookie.indexOf('access_token='));

if (hasAccessToken) {
    const path = window.location.split('/')[1];
    document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Path=/${path}; Secure`;
    window.location.reload()
}
